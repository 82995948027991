import { Link } from 'gatsby';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/home/_homePayment.scss';

class HomePayment extends Component {
  render() {
    return (
      <>
        <section className="home-payment-content">
          <Container fluid>
            <Row className="align-items-center h-100 p-5">
              <Col md={8} className="mx-auto text-center">
                <p className="home-payment-discription mb-0">
                  Take advantage of our in-house{' '}
                  <span className="home-payment-plan">membership plan!</span>
                </p>
              </Col>
              <Col md={4} className="mx-auto text-center home-payment-col">
                <Link href="/contact" className="home-payment-button">
                  Learn More
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default HomePayment;
