import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import '../../scss/home/_homeValue.scss';

class HomeValue extends Component {
  render() {
    const { homeData } = this.props;
    return (
      <>
        <section className="home-value-content">
          <Container fluid>
            <Row className="align-items-center h-100 py-5 px-2">
              <Col md={12} className="text-center home-value-sectionTitle mb-4">
                <h1>Why Choose Us?</h1>
              </Col>
              <Col md={4} className="mx-auto text-center">
                <Card className="shadow border-0 home-value-cards mb-2">
                  <Card.Body>
                    <Card.Title className="home-value-title mb-3">
                      {homeData[0].valueSection.trustedTitle}
                    </Card.Title>
                    <Card.Text className="home-value-discription">
                      {homeData[0].valueSection.trustedDiscription}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mx-auto text-center">
                <Card className="shadow border-0 home-value-cards mb-2">
                  <Card.Body>
                    <Card.Title className="home-value-title mb-3">
                      {homeData[0].valueSection.caringTitle}
                    </Card.Title>
                    <Card.Text className="home-value-discription">
                      {homeData[0].valueSection.caringDiscription}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mx-auto text-center">
                <Card className="shadow border-0 home-value-cards mb-2">
                  <Card.Body>
                    <Card.Title className="home-value-title mb-3">
                      {homeData[0].valueSection.convenientTitle}
                    </Card.Title>
                    <Card.Text className="home-value-discription">
                      {homeData[0].valueSection.convenientDiscription}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mt-4">
                <a
                  href={homeData[0].gumDiseaseFacts.gumDiseaseFactsLink}
                  className="home-value-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {homeData[0].gumDiseaseFacts.title}
                </a>
              </Col>
              <Col md={4} className="mt-4">
                <a
                  href={homeData[0].gumDiseaseWhole.gumDiseaseWholeLink}
                  className="home-value-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {homeData[0].gumDiseaseWhole.title}
                </a>
              </Col>
              <Col md={4} className="mt-4">
                <a
                  href={
                    homeData[0].gumDiseaseTreatments.gumDiseaseTreatmentsLink
                  }
                  className="home-value-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {homeData[0].gumDiseaseTreatments.title}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// export default Home;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HomeValueQuery {
        allHomeJson {
          nodes {
            valueSection {
              trustedTitle
              trustedDiscription
              caringTitle
              caringDiscription
              convenientTitle
              convenientDiscription
            }
            gumDiseaseFacts {
              title
              gumDiseaseFactsLink
            }
            gumDiseaseTreatments {
              title
              gumDiseaseTreatmentsLink
            }
            gumDiseaseWhole {
              title
              gumDiseaseWholeLink
            }
            gumDiseaseStroke {
              title
              gumDiseaseStrokeLink
            }
            gumDiseaseBreast {
              title
              gumDiseaseBreastLink
            }
          }
        }
      }
    `}
    render={(data) => <HomeValue homeData={data.allHomeJson.nodes} />}
  />
);

HomeValue.propTypes = {
  homeData: PropTypes.array.isRequired,
};
