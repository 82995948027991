import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/home/_homePortal.scss';

class HomePortal extends Component {
  render() {
    const { contactData } = this.props;
    return (
      <>
        <section className="home-portal-content">
          <Container fluid>
            <Row className="align-items-center h-100 pt-5 px-2">
              <Col
                md={12}
                className="text-center home-portal-sectionTitle mb-4"
              >
                <h1>New Patient Forms</h1>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center h-100 px-2">
              <Col
                md={6}
                className="text-center justify-content-center home-portal-discription"
              >
                <p>
                  Please click the link below to complete your new patient
                  registration online prior to your first visit. Once completed,
                  all of your information will be submitted through our secure
                  portal.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center h-100 pt-2 pb-5 px-2">
              <Col md={4} className="text-center">
                <a
                  href={
                    contactData[0].clinicInfo.henricoPatientPortal
                      .henricoPatientPortalLink
                  }
                  className="home-portal-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Henrico Office
                </a>
              </Col>
              <Col md={4} className="text-center">
                <a
                  href={
                    contactData[0].clinicInfo.midlothianPatientPortal
                      .midlothianPatientPortalLink
                  }
                  className="home-portal-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Midlothian Office
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HomePortalQuery {
        allContactJson {
          nodes {
            clinicInfo {
              henricoPatientPortal {
                henricoPatientPortalLink
              }
              midlothianPatientPortal {
                midlothianPatientPortalLink
              }
            }
          }
        }
      }
    `}
    render={(data) => <HomePortal contactData={data.allContactJson.nodes} />}
  />
);

HomePortal.propTypes = {
  contactData: PropTypes.array.isRequired,
};
