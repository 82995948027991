import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import '../../scss/home/_homeServices.scss';

class HomeServices extends Component {
  render() {
    return (
      <>
        <section className="home-services-content">
          <Container fluid>
            <Row className="align-items-center h-100 py-5 px-2">
              <Col
                md={12}
                className="text-center home-services-sectionTitle mb-4"
              >
                <h1>Our Services</h1>
              </Col>
              <Col md={12}>
                <Table
                  borderless
                  responsive
                  className="text-center  home-services-list d-none d-sm-table"
                >
                  <tbody>
                    <tr>
                      <td>Laser Periodontal Therapy</td>
                      <td>Gum Disease Treatment</td>
                      <td>Gum Grafting</td>
                      <td>Bone Grafting</td>
                    </tr>
                    <tr>
                      <td>Dental Implants</td>
                      <td>Sinus Lift</td>
                      <td>All-on-4</td>
                      <td>Periodontal Surgery</td>
                    </tr>
                    <tr>
                      <td>Crown Lengthening</td>
                      <td>Frenectomy</td>
                      <td>Platelet Rich Fibrin</td>
                      <td>Sedation Dentistry</td>
                    </tr>
                  </tbody>
                </Table>
                <Table
                  borderless
                  responsive
                  className="text-center align-middle home-services-list d-block d-sm-none"
                >
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        Laser Periodontal Therapy
                      </td>
                      <td className="align-middle">Gum Disease Treatment</td>
                      <td className="align-middle">Gum Grafting</td>
                    </tr>
                    <tr>
                      <td className="align-middle">Bone Grafting</td>
                      <td className="align-middle">Dental Implants</td>
                      <td className="align-middle">Sinus Lift</td>
                    </tr>
                    <tr>
                      <td className="align-middle">All-on-4</td>
                      <td className="align-middle">Periodontal Surgery</td>
                      <td className="align-middle">Crown Lengthening</td>
                    </tr>
                    <tr>
                      <td className="align-middle">Frenectomy</td>
                      <td className="align-middle">Platelet Rich Fibrin</td>
                      <td className="align-middle">Sedation Dentistry</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default HomeServices;
