import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Carousel, Card, Container, CardDeck, Row, Col } from 'react-bootstrap';
import '../../scss/home/_homeMembership.scss';

class HomeMembership extends Component {
  render() {
    const {
      aaidImage,
      aapImage,
      aofImage,
      itiImage,
      vdaImage,
      adaImage,
      lanapImage,
      fightgum,
    } = this.props;
    return (
      <>
        <section className="membership-content h-100">
          <Container className="membership-content-containter p-3">
            <Row className="membership-row d-none d-sm-block">
              <Col md={12}>
                <CardDeck>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={aaidImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={aapImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={aofImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={itiImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={vdaImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={adaImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={lanapImage.childImageSharp.fluid.src}
                    />
                  </Card>
                  <Card className="membership-cards">
                    <Card.Img
                      variant="top"
                      className="membership-cards-image img-fluid"
                      src={fightgum.childImageSharp.fluid.src}
                    />
                  </Card>
                </CardDeck>
              </Col>
            </Row>
            <Carousel
              controls={false}
              indicators={false}
              className="d-block d-sm-none"
            >
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm">
                  <Col sm={12}>
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={aaidImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={aapImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={aofImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={itiImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={vdaImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={adaImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={lanapImage.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={2000} className="home-content-image">
                <Row className="membership-row-sm .d-block .d-sm-none">
                  <Col sm={12} className="mx-auto">
                    <Card className="membership-cards-sm">
                      <Card.Img
                        variant="top"
                        className="membership-cards-image-sm img-fluid"
                        src={fightgum.childImageSharp.fluid.src}
                      />
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Container>
        </section>
      </>
    );
  }
}

// export default Home;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HomeMembershipQuery {
        aaidImage: file(relativePath: { eq: "memberships/aaid.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aapImage: file(relativePath: { eq: "memberships/aap.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aofImage: file(relativePath: { eq: "memberships/aof.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        itiImage: file(relativePath: { eq: "memberships/iti.png" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        vdaImage: file(relativePath: { eq: "memberships/vda.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adaImage: file(relativePath: { eq: "memberships/ada.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lanapImage: file(relativePath: { eq: "memberships/lanap.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        fightgumImage: file(relativePath: { eq: "memberships/fightgum.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <HomeMembership
        aaidImage={data.aaidImage}
        aapImage={data.aapImage}
        aofImage={data.aofImage}
        itiImage={data.itiImage}
        vdaImage={data.vdaImage}
        adaImage={data.adaImage}
        lanapImage={data.lanapImage}
        fightgum={data.fightgumImage}
      />
    )}
  />
);

HomeMembership.propTypes = {
  aaidImage: PropTypes.object.isRequired,
  aapImage: PropTypes.object.isRequired,
  aofImage: PropTypes.object.isRequired,
  itiImage: PropTypes.object.isRequired,
  vdaImage: PropTypes.object.isRequired,
  adaImage: PropTypes.object.isRequired,
  lanapImage: PropTypes.object.isRequired,
  fightgum: PropTypes.object.isRequired,
};
