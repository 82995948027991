import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';
import '../../scss/home/_home.scss';
import HomeAbout from './HomeAbout';
import HomeMembership from './HomeMembership';
import HomeValue from './HomeValue';
import HomePayment from './HomePayment';
import HomeServices from './HomeServices';
import HomePortal from './HomePortal';
import HomeSunbit from './HomeSunbit';

class Home extends Component {
  render() {
    const { homeData, contactData, backgroundImages } = this.props;
    return (
      <>
        <section className="home-content h-100">
          <Container fluid className="home-content-containter">
            <Carousel controls={false} indicators={false}>
              {backgroundImages.nodes.map((image) => (
                <Carousel.Item
                  interval={3000}
                  className="home-content-image"
                  key={image.id}
                >
                  <Img
                    className="d-block h-100"
                    fluid={image.childImageSharp.fluid}
                    alt="Slide"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <Row className="main-text w-100">
              <Col className="text-center w-100">
                <div className="home-clinic-name">
                  <span className="pb-3">
                    {contactData[0]?.clinicInfo.clinicName}
                  </span>
                </div>
                <div className="home-tagline my-5">
                  <span>{homeData[0]?.landingPage?.tagLineOne}</span>
                  <span>{homeData[0]?.landingPage?.tagLineTwo}</span>
                </div>
                <Link
                  to={homeData[0]?.landingPage?.bookAnAppoinmentLink}
                  className="home-book-now"
                >
                  {homeData[0]?.landingPage?.bookAnAppoinment}
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
        <HomeAbout />
        <HomeValue />
        <HomePayment />
        <HomeServices />
        <HomeMembership />
        <HomePortal />
        <HomeSunbit />
      </>
    );
  }
}

// export default Home;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        allHomeJson {
          nodes {
            landingPage {
              tagLineOne
              tagLineTwo
              bookAnAppoinment
              bookAnAppoinmentLink
            }
          }
        }

        allContactJson {
          nodes {
            clinicInfo {
              clinicName
            }
          }
        }

        backgroundImages: allFile(
          filter: { relativeDirectory: { eq: "home-slides" } }
        ) {
          nodes {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Home
        homeData={data.allHomeJson.nodes}
        contactData={data.allContactJson.nodes}
        backgroundImages={data.backgroundImages}
      />
    )}
  />
);

Home.propTypes = {
  homeData: PropTypes.array.isRequired,
  contactData: PropTypes.array.isRequired,
  backgroundImages: PropTypes.object.isRequired,
};
