import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/home/_homeAbout.scss';

class HomeAbout extends Component {
  render() {
    const { homeData, greenTulips, drPatel } = this.props;
    return (
      <section className="home-about-content">
        <Container fluid>
          <Row className="align-items-center h-100 py-5">
            <Col md={6} className="mx-auto text-center">
              <div className="home-dr-name py-4">
                <img
                  src={greenTulips}
                  alt="Green Tulips"
                  className="tulips mx-auto mb-2 "
                />
                <span className="pb-3">{homeData[0]?.aboutSection.drName}</span>
              </div>
              <p className="home-dr-discription">
                {homeData[0]?.aboutSection.drDiscription}
              </p>
            </Col>
            <Col md={6} className="mx-auto text-center">
              <img
                src={drPatel.childImageSharp.fluid.src}
                alt="Dr. Mitul Patel Images"
                className="dr-image rounded mx-auto shadow-lg"
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HomeAboutQuery {
        allHomeJson {
          nodes {
            aboutSection {
              drName
              drDiscription
            }
          }
        }

        greenTulips: file(relativePath: { eq: "tulips/green-tulips.svg" }) {
          id
          publicURL
        }

        drPatel: file(relativePath: { eq: "doctor-photo/dr-patel.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <HomeAbout
        homeData={data.allHomeJson.nodes}
        greenTulips={data.greenTulips.publicURL}
        drPatel={data.drPatel}
      />
    )}
  />
);

HomeAbout.propTypes = {
  homeData: PropTypes.array.isRequired,
  greenTulips: PropTypes.string.isRequired,
  drPatel: PropTypes.object.isRequired,
};
